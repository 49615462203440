.documents {
  &__title, &__subtitle{
    display: block;
  }

  &__title, &__subtitle{
    margin-bottom: 20px;
  }

  &__list{
    display: grid;
  }

  &__item{
    display: flex;
    align-items: center;
    img{
      width: 70px;
      height: 70px;
      margin-right: 30px;
      border-radius: 100%;
    }
  }

  &__report-link{
    display: block;
    margin-top: 20px;
    font-size: 25px;
  }
}

@include respond-up('s-large') {
  .documents {
    padding: 40px;
    &__list{
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }

    &__report-link{
      padding-bottom: 3px;
      border-bottom: 2px solid transparent;
      transition: border .3s;
      width: fit-content;
      &:hover{
        border-bottom: 2px solid black;
      }
    }

    &__name{
      font-size: 25px;
    }
  }
}

@include respond-up('medium') {
  .documents {

  }
}

@include respond('medium') {
  .documents {
    padding: 30px;
  }
}

@include respond-down('medium') {
  .documents {
    &__item{
      margin-bottom: 20px;
    }

    &__report-link{
      border-bottom: 2px solid black;
      padding-bottom: 3px;
      width: fit-content;
    }
  }
}

@include respond-down('small') {
  .documents {
    padding: 20px;
  }
}