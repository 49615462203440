.donate{
  &__items{
    display: grid;
  }
  &__title, &__subtitle{
    margin-bottom: 20px;
  }

  &__mixplat-link{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &__operators{
    margin: 20px 0;
  }
}

@include respond-up('s-large'){
  .donate{
    padding: 40px;
    &__text{
      font-size: 25px;
      margin-bottom: 25px;
    }

    &__info{
      display: flex;
      padding: 20px 0;
    }

    &__information-links{
      font-size: 25px;
      list-style: circle;
      margin-left: 20px;
      margin-right: 100px;
    }

    &__information-link{
      border-bottom: 1px solid transparent;
      transition: border .3s;
      &:hover{
        border-bottom: 1px solid black;
      }
    }
  }
}

@include respond-up('medium'){
  .donate{

  }
}

@include respond('medium'){
  .donate{
    padding: 30px;

    &__text{
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}

@include respond-down('medium'){
  .donate{
    &__text{
      font-size: 18px;
      margin-bottom: 20px;
    }

    &__information-links{
      font-size: 16px;
      list-style: circle;
      margin-left: 20px;
    }

    &__information-link{
      border-bottom: 1px solid black;
      padding-bottom: 2px;
      margin-bottom: 10px;
      display: inline-block;
    }

    &__info{
      img{
        margin: 20px 0;
      }
    }
  }
}

@include respond-down('small'){
  .donate{
    padding: 20px;
  }
}