.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__title {
    margin-bottom: 20px;
    text-align: left;
  }
  &__container{
    columns: 5 200px;
    column-gap: 1.5rem;
    width: 90%;
    margin: 0 auto;

    a {
      width: 100%;
      margin: 0 1.5rem 1.5rem 0;
      display: inline-block;
      border: solid 2px black;
      padding: 5px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      transition: all .25s ease-in-out;

      &:hover {
        border-color: coral;
      }

      img {
        width: 100%;
        border-radius: 5px;
        transition: all .25s ease-in-out;
      }
    }
  }
}

@include respond-up('s-large') {
  .gallery{
    padding: 40px;
  }
}

@include respond-up('medium') {
  .gallery {

  }
}

@include respond('medium') {
  .gallery {
    padding: 30px;
  }
}

@include respond-down('medium') {
  .gallery {

  }
}

@include respond-down('small') {
  .gallery {
    padding: 20px;
  }
}