@font-face {
    font-family: "a_Gildia Bold";
    src: url("a-gildia-bold.eot"); /* IE9 Compat Modes */
src: url("a-gildia-bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
url("a-gildia-bold.otf") format("opentype"), /* Open Type Font */
url("a-gildia-bold.svg") format("svg"), /* Legacy iOS */
url("a-gildia-bold.ttf") format("truetype"), /* Safari, Android, iOS */
url("a-gildia-bold.woff") format("woff"), /* Modern Browsers */
url("a-gildia-bold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
