.donation{
  &__title, &__subtitle{
    margin-bottom: 20px;
  }

  &__text{
    ol{
      margin: 20px 0;
      list-style: circle;
      li{
        margin-left: 20px;
      }
    }
  }

  &__add-title{
    @extend h3;
    margin-bottom: 20px;
  }

  &__buttons-wrap{
    margin-top: 20px;
    display: flex;
  }
}

@include respond-up('s-large'){
  .donation{
    align-self: center;
    padding: 40px calc(200/1920 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;



    &__text{
      font-size: 25px;
    }

    &__button{
      &:first-child{
        margin-right: 20px;
      }
    }
  }
}

@include respond('medium'){
  .donation{
    padding: 30px;
    &__button{
      &:first-child{
        margin-right: 20px;
      }
    }

    &__text{
      font-size: 18px;
    }
  }
}

@include respond-down('small'){
  .donation{
    padding: 20px;
    &__buttons-wrap{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__button{
      &:first-child{
        margin-bottom: 20px;
      }
    }
  }
}