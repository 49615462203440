.connect-page {
  &__title, &__subtitle{
    margin-bottom: 20px;
    display: block;
  }
  &__link{
    position: relative;
    img{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:nth-child(2) {
      img{
        transform: scale(-1, 1);
      }
    }

    &:nth-child(3) {
      img{
        transform: scale(1, -1);
      }
    }

    &:nth-child(4) {
      img{
        transform: scale(-1, -1);
      }
    }
  }

  &__link-wrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__item-title{
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: rgba(255, 255, 255, 0.6) 1px 1px 1px, rgba(0, 0, 0, 0.6) -1px -1px 1px;
  }

  &__item-subtitle{
    color: black;
    text-shadow: #ffffff -1px -1px 0px, #ffffff -1px 1px 0px, #ffffff 1px 1px 0px, #ffffff 1px -1px 0px;
  }
}

@include respond-up('s-large') {
  .connect-page {
    padding: 40px;
    &__links{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }

    &__link{
      height: 400px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(0.3);
        transition: filter .5s;
      }

      &:hover{
        img{
          filter: grayscale(0);
        }

        .connect-page{
          &__item-subtitle{
            opacity: 1;
          }
        }
      }
    }

    &__item-title{
      font-size: 30px;
    }
    &__item-subtitle{
      font-size: 21px;
      margin-top: 30px;
      opacity: 0;
      transition: opacity .5s;
    }
  }
}

@include respond-up('medium') {
  .connect-page {

  }
}

@include respond('medium') {
  .connect-page {
    padding: 30px;
    &__links{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }
  }
}

@include respond-down('medium') {
  .connect-page {
    &__link{
      height: 300px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__link-wrap{
      padding: 10px;
    }

    &__item-title{
      font-size: 25px;
      text-align: center;
    }
    &__item-subtitle{
      margin-top: 20px;
      font-size: 18px;
      text-align: center;
    }
  }
}

@include respond-down('small') {
  .connect-page {
    padding: 20px;
    &__links{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
  }
}