%text-main {
  font-family: oswald;
  line-height: 1.75em;
  text-align: justify;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  color: var(--primary-color);
  font-stretch: 110%;
  font-variation-settings: var(--font-settings);
}

@include respond-up("s-large") {
  %text-main {
    --font-size: 28px;
  }
}

@include respond("medium") {
  %text-main {
    --font-size: calc((20 / 834) * 100vw);
  }
}

@include respond-down("small") {
  %text-main {
    --font-size: 18px;
  }
}