h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "oswald";
  font-size: var(--h-size);
  text-transform: uppercase;
  font-style: normal;
}

h1, .h1, h2, .h2, h3, .h3 {
}

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 500;
  line-height: 110%;
}

h1{
  color: #214026;
  line-height: 1.41em;
  letter-spacing: 0;
  text-shadow: rgba(0, 0, 0, 0.4) 0 4px 5px;
  text-align: center;
}

h2{
  color: #e21c20;
  text-shadow: rgba(0, 0, 0, 0.4) 0 4px 5px;
  line-height: 1.41em;
  text-align: center;
}

h3{
  color: rgb(21,54,27);
  text-shadow: rgba(0, 0, 0, 0.4) 0 4px 5px;
  line-height: 1.41em;
  text-align: center;
  font-weight: 600;
}

@include respond-up("s-large") {
  h1, .h1 {
    --h-size: 54px;
  }

  h2, .h2 {
    --h-size: 40px;
  }

  h3, .h3 {
    --h-size: 25px;
  }

  h4, .h4 {
    --h-size: 25px;
  }

  h5, .h5 {
    --h-size: 16px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond("medium") {
  h1, .h1 {
    --h-size: calc((40 / 834) * 100vw);
  }

  h2, .h2 {
    --h-size: calc((30 / 834) * 100vw);
  }

  h3, .h3 {
    --h-size: calc((25 / 834) * 100vw);
  }

  h4, .h4 {
    --h-size: 22px;
  }

  h5, .h5 {
    --h-size: 16px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond-down("small") {
  h1, .h1 {
    --h-size: 29px;
  }

  h2, .h2 {
    --h-size: 22px;
  }

  h3, .h3 {
    --h-size: 20px;
  }

  h4, .h4 {
    --h-size: 18px;
  }

  h5, .h5 {
    --h-size: 14px;
  }

  h6, .h6 {
    --h-size: 14px;
  }
}
