.layout {

}

@include respond-up('s-large') {
  .layout {
    &__content{
      padding-bottom: 40px;
    }
  }
}

@include respond-up('medium') {
  .layout {
    &__content{
    }
  }
}

@include respond('medium') {
  .layout {
    &__content{
      padding-bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .layout {

  }
}

@include respond-down('small') {
  .layout {
    &__content{
      padding-bottom: 20px;
    }
  }
}