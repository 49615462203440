.media-index {
  &__list{
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
}

@include respond-up('s-large') {
  .media-index {
    padding: 40px;
  }
}

@include respond-up('medium') {
  .media-index {

  }
}

@include respond('medium') {
  .media-index {
    padding: 30px;
  }
}

@include respond-down('medium') {
  .media-index {

  }
}

@include respond-down('small') {
  .media-index {
    padding: 20px;
  }
}