.member {
  &__date{
    display: flex;
    align-items: center;
  }

  &__date-point{
    margin-right: 5px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #15361b;
  }

  &__item-title, &__item-subtitle{
    color: #15361b;
  }

  &__image-container{
    position: relative;
    margin: 30px 0;
    width: 100%;
    height: auto;
  }

  &__picture{
    //position: absolute;
    //top: 0;
    //left: 0;
    //right: 0;
    //bottom: 0;
  }

  &__image{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@include respond-up('s-large') {
  .member {
    padding: 40px;
    &__item-title{
      font-size: calc(35/1980 * 100vw);
      line-height: 39px;
      margin-bottom: 20px;
    }

    &__date{
      margin-bottom: 30px;
    }

    &__image-container{
      //height: calc(700/1920 * 100vw);
    }
  }
}

@include respond-up('medium') {
  .member {

  }
}

@include respond('medium') {
  .member {
    padding: 30px;
    &__item-title{
      font-size: calc(25/1000 * 100vw);
      margin-bottom: 20px;
    }

    &__date{
      margin-bottom: 15px;
    }

    &__image-container{
      //height: calc(500/1200 * 100vw);
    }
  }
}

@include respond-down('medium') {
  .member {

  }
}

@include respond-down('small') {
  .member {
    padding: 20px;
    &__item-title{
      font-size: 25px;
      margin-bottom: 20px;
    }

    &__date{
      margin-bottom: 15px;
    }

    &__image-container{
      //padding: calc(250/768 * 100vw)
    }
  }
}