.areas {
  &__title, &__subtitle, &__item-title{
    display: block;
  }

  &__item-title{
  }

  &__title, &__subtitle, &__item-title{
    margin-bottom: 20px;
  }

  &__image{
    margin: 20px 0;
    img{
      width: 100%;
    }
  }

  &__more-button{
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }

  &__item{
    border-bottom: 1px solid lightgrey;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:last-child{
      border-bottom: unset;
    }
  }
}

@include respond-up('s-large') {
  .areas {
    padding: 40px;
    &__item-description{
      font-size: 28px;
    }
  }
}

@include respond-up('medium') {
  .areas {

  }
}

@include respond('medium') {
  .areas {
    padding: 30px;
    &__item-description{
      font-size: 25px;
    }
  }
}

@include respond-down('medium') {
  .areas {

  }
}

@include respond-down('small') {
  .areas {
    padding: 20px;
    &__item-description{
      font-size: 20px;
    }
  }
}