article {
  overflow: hidden;
  &, p, li {
    font-size: 24px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  div{
    &.float-left{
      float: left;
      padding-right: 25px;
    }

    &.float-right{
      float: right;
      padding-left: 25px;
    }
  }

  img{
    max-height: 500px;
    width: auto;
  }
  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ol, ul{
    li{
      margin-left: 30px;
      margin-bottom: 10px;
    }
  }
}

@include respond-up('s-large'){
  article{
    &, p, li {
      font-size: 24px;
      text-align: justify;
    }
  }
}

@include respond('medium'){
  article{
    &, p, li {
      font-size: 25px;
    }
  }
}

@include respond-down('small'){
  article{
    &, p, li {
      font-size: 20px;
    }
  }
}