.header-menu {
  background-color: #FFFFFB;
}

@include respond-up('s-large') {
  .header-menu {
    &__wrap{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    &__submenu{
      position: absolute;
      top: 50px;
      left: 0;
      transition: opacity .3s, background-color .3s, color .3s;
      pointer-events: none;
      opacity: 0;
      color: black;
      background: #fffffb;
      -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    }

    &__submenu-wrap{
      display: flex;
      flex-direction: column;
      padding: 10px 0;
    }

    &__submenu-link{
      padding: 10px 10px;
    }

    &__menu-link{
      padding: 15px 20px;
      background-color: transparent;
      transition-property: background-color, color;
      transition-timing-function: ease;
      transition-duration: .5s;
      position: relative;
      &:first-child{
        margin-left: -20px;
      }

      &:hover{
        color: #FFFFFF;
        background-color: #214026;
        .header-menu{
          &__submenu{
            z-index: 99999;
            background: #fffffb;
            color: black;
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }

    &__submenu-link{
      color: black;
      background: transparent;
      transition: background-color .3s, color .3s;
      display: block;
      &:hover{
        background-color: #214026;
        color: #FFFFFF;
      }
    }
  }
}

@include respond-up('medium') {
  .header-menu {
    &__closer{
      display: none;
    }
  }
}

@include respond('medium') {
  .header-menu {
    &__menu-link{
      font-size: 30px;
    }
  }
}

@include respond-down('medium') {
  .header-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
    z-index: 0;
    opacity: 0;
    height: 100vh;
    pointer-events: none;
    transition: opacity .4s;
    -webkit-box-shadow: 4px 5px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 5px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 5px 10px 2px rgba(34, 60, 80, 0.2);

    &._opened{
      opacity: 1;
      pointer-events: all;
      z-index: 99999;
      overflow: scroll;
    }
    &__container{
      height: fit-content;
      padding: 30px 20px;
    }

    &__wrap{
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      justify-content: center;
      height: 100%;
    }

    &__menu-link{
      text-align: center;
    }
  }
}

@include respond-down('small') {
  .header-menu {
    right: 0;

    &__wrap{
      align-items: center;
      row-gap: 10px;
    }

    &__menu-link{
      font-size: 25px;
    }

    &__closer{
      z-index: 9999;
      position: absolute;
      width: 20px;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
    }

    &__line{
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #000000;
      &_top {
        transform: rotate(45deg) translate3d(-4px,5px,0)
      }

      &_bottom {
        transform: rotate(-45deg) translate3d(-5px,-4px,0);
      }
    }
  }
}