.header {
  width: 100%;
  z-index: 4700;
  background-color: #FFFFFB;

  &__image-container{
    width: 100%;
    height: 370px;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__wrap {
    position: relative;
    height: 370px;
    //&:before{
    //  content: '';
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  bottom: 0;
    //  background: linear-gradient(180deg, rgba(10, 18, 31, 0.3) 0%, rgba(10, 18, 31, 0.00) 100%);
    //  z-index: 1;
    //}
  }

  &__text-wrap{
    padding-top: 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__logo-wrap{
    display: flex;
    align-items: center;
  }

  &__logo {
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate3d(-50%, -50%, 0);
    //height: 100%;
    //display: flex;
    //align-items: center;
  }

  &__logo-item{
    font-size: 40px;
    text-transform: uppercase;
    font-family: var(--font-guildia);
    letter-spacing: 2px;
    padding-top: 75px;
    &:first-child{
      margin-right: 20px;
      text-align: right;
    }

    &:last-child{
      margin-left: 20px;
      text-align: left;
    }
  }

  &__logo-item-text{
    &_second {
      margin-left: -7px;
    }
    &_first{
      margin-right: 5px;
    }
  }

  &__text-part{
    font-size: 40px;
    text-transform: uppercase;
    color: #068542;
    font-family: var(--font-guildia);
    letter-spacing: 1.4px;
    text-shadow: #ffffff -1px -1px 0px, #ffffff -1px 1px 0px, #ffffff 1px 1px 0px, #ffffff 1px -1px 0px;
    &_red{
      font-size: 38px;
      color: #E21C21;
      text-align: center;
    }

    &_black{
      font-family: var(--font);
      text-transform: none;
      letter-spacing: 0;
      color: black;
      font-size: 25px;
      text-shadow: rgba(0, 0, 0, 0.4) 0 4px 5px;
    }
    &_small{
      font-size: 20px;
    }
  }
}

@include respond-up('s-large') {
  .header{
    //&__text-part{
    //  &_red{
    //    padding-bottom: 20px;
    //  }
    //  &_black{
    //    padding-top: 30px;
    //  }
    //}
  }
}
@include respond('medium') {
  .header{
    &__image{
      object-position: right;
    }
  }
}

@include respond-down('medium') {
  .header{
    &__item-image{
      display: none;
    }

    &__wrap {
      height: 500px;

    }

    &__text-part{
      &_red{
        text-align: center;
        max-width: 700px;
      }
    }

    &__hamburger-wrap{
      display: flex;
      justify-content: flex-end;
      background-color: #FFFFFB;
    }
  }
}

@include respond-down('small') {
  .header{
    &__image{
      object-position: right;
    }

    &__logo-wrap{
      display: grid;
      grid-template-areas: "logo text1"
                           "logo text2";
    }

    &__wrap{
      height: 300px;
    }
    &__text-wrap{
      margin: 0 10px;
      align-items: center;
      justify-content: center;
    }

    &__text-part{
      font-size: 20px;
      padding: 0;
      &:first-child{
        grid-area: text1;
        margin-left: 10px;
        text-align: left;
      }
      &:last-child{
        grid-area: text2;
        margin-left: 10px;
        text-align: left;
      }

      &:nth-child(1n+3) {
        margin-bottom: 20px;
        text-align: center;
      }
    }

    &__logo{
      grid-area: logo;
      width: 50px;
    }
  }
}
