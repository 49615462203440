.footer {
  background: #a3cc7a;
  &__support-first, &__support-second{
    color: black;
  }

  &__support-first{
    font-weight: 400;
    text-align: center;
  }

  &__support-second{
    font-weight: 400;
    text-align: center;
  }

  &__support-third{
    color: #9c171b;
    font-weight: 700;
    text-align: center;
  }

  &__support-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__contacts{
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }

  &__phone{
    margin-left: 20px;
  }

  &__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__socials-item{
    display: flex;
    align-items: center;
    margin-top: 20px;

    img{
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }

  &__age{
    width: 95px;
    height: 95px;
    border-radius: 100%;
    background-color: #214026;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
  }
}

@include respond-up('s-large') {
  .footer {
    &__socials{
      position: relative;
    }

    &__qr{
      position: absolute;
      width: 130px;
      height: 130px;
      left: 0;
      top: -30px;
    }

    &__age{
      position: absolute;
      left: 170px;
      top: -15px;
    }

    &__support{
      bottom: -30px;
      position: absolute;
      right: -90px;
      width: 250px;
      height: 250px;
    }
  }
}

@include respond-up('medium') {
  .footer {
    padding: 30px 0;
    &__support-first{
      font-size: 16px;
      color: black;
    }

    &__support-second{
      font-size: 27px;
    }

    &__support-third{
      font-size: 41px;
    }
  }
}

@include respond('medium') {
  .footer {

  }
}

@include respond-down('medium') {
  .footer {
    &__qr{
      width: 130px;
      height: 130px;
    }

    &__age{
      margin-left: 50px;
    }

    &__support{
      margin-left: 50px;
      width: 140px;
      height: 140px;
    }

    &__add-wrap{
      margin-top: 30px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

@include respond-down('small') {
  .footer {
    padding: 20px 0;
    &__support-first{
      font-size: 16px;
    }

    &__support-second{
      font-size: 27px;
    }

    &__support-third{
      font-size: 30px;
    }
  }
}
