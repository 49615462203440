.info-index{
  &__title{
    font-size: 30px;
    margin-bottom: 20px;
    display: block;
  }

  &__documents{
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid lightgray;
  }

  &__content{
    article{
      font-family: var(--h-font);
    }
  }
}

@include respond-up('s-large'){
  .info-index{
    padding: 40px;
  }
}

@include respond('medium') {
  .info-index{
    padding: 20px;
  }
}

@include respond-down('medium') {
  .info-index{
    &__link-arrow{
      margin-left: 30px;
    }
  }
}

@include respond-down('small') {
  .info-index{
    padding: 20px;
  }
}