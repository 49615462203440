.requisites{
  &__items{
    display: grid;
  }
  &__title, &__subtitle{
    margin-bottom: 20px;
  }

  &__item-title{
    font-weight: bold;
  }
  &__item{
    margin-bottom: 15px;
    font-size: 25px;
  }
}

@include respond-up('s-large'){
  .requisites{
    padding: 40px;
    &__text{
      font-size: 25px;
    }

    &__button{
      &:first-child{
        margin-right: 20px;
      }
    }
  }
}

@include respond-up('medium'){
  .requisites{
    &__item{
      &:first-child{
        grid-column: 1 / 3;
      }
    }
    &__items{
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
    }
  }
}

@include respond('medium'){
  .requisites{
    padding: 30px;
    &__button{
      &:first-child{
        margin-right: 20px;
      }
    }

    &__text{
      font-size: 18px;
    }
  }
}

@include respond-down('small'){
  .requisites{
    padding: 20px;
    &__buttons-wrap{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__button{
      &:first-child{
        margin-bottom: 20px;
      }
    }
  }
}