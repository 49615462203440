.videos {
  &__list{
    display: grid;
    grid-gap: 10px;
  }

  &__title{
    display: block;
    margin-bottom: 20px;
  }

  &__link{
    display: inline-block;
    border: solid 2px black;
    padding: 5px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    img {
      width: 100%;
      border-radius: 5px;
    }
  }

  &__item-title{
    margin-top: 10px;
  }
}

@include respond-up('s-large') {
  .videos {
    padding: 40px;
    &__list{
      grid-template-columns: repeat(3, 1fr);
    }

    &__link{
      transition: all .25s ease-in-out;

      &:hover {
        border-color: coral;
      }

      img {
        transition: all .25s ease-in-out;
      }
    }
  }
}

@include respond-up('medium') {
  .videos {

  }
}

@include respond('medium') {
  .videos {
    padding: 30px;
    &__list{
      grid-template-columns: repeat(2, 1fr);
    }

    &__link{

    }
  }
}

@include respond-down('medium') {
  .videos {

  }
}

@include respond-down('small') {
  .videos {
    padding: 20px;
    &__list{
      grid-template-columns: 1fr;
    }
  }
}