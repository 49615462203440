.tabs {
  list-style: none;
  display: flex;
  justify-content: flex-start;

  &__link {
    position: relative;
  }

  li {
    a {
      display: block;
    }
  }

  &_choice {
    font-size: 20px;
    background: transparent;
    margin: -3px;

    .tabs {
      &__item {
        padding: 3px;

        &:not(:first-child) {
          //margin-left: 6px;
        }

        &._active {
          .tabs {
            &__link {
              background: #15361b;
              color: #FFFFFF;
            }
          }
        }
      }

      &__link {
        padding: 8px 14px;
        display: flex;
        align-items: center;
        background: transparent;
        color: #000;
        transition: background-color .3s, color .3s;
        white-space: nowrap;
      }
    }
  }
}

@include respond-up("medium") {
  .tabs {
    &_choice {
      .tabs {
        &__link {
          font-size: 15px;
        }
      }
    }
  }
}

@include respond("medium") {
  .tabs {
    &_delivery {
      .tabs {
        &__link {
          padding: 13px 20px;
        }
      }
    }
  }
}

@include respond-down("medium") {
  .tabs {
    &_product-card {
      .tabs {
        &__link {
          padding: 14px 20px;
        }
      }
    }

    &_contacts {
      .tabs {
        &__link {
          padding: 15px;
        }
      }
    }

    &_delivery {
      .tabs {
        &__link {
          span {
            white-space: nowrap;
          }
        }

        &__item {
          //flex: 0 0 calc(50% - 0.5px);

          &:not(:first-child) {
            margin-left: 1px;
          }
        }
      }
    }

    &_lk {
      .tabs {
        &__link {
          min-width: 115px;
        }
      }
    }
  }
}

@include respond-down("small") {
  .tabs {
    &_choice {
      .tabs {
        &__link {
          font-size: 14px;
        }
      }
    }

    &_choice-orange {
      margin: -4px;

      .tabs {
        &__item {
          margin: 4px;
        }
      }
    }

    &_delivery {
      .tabs {
        &__link {
          padding: 14px 20px;
        }
      }
    }
  }
}
