.team {
  &__title, &__subtitle, &__item-title{
    display: block;
  }

  &__title, &__subtitle, &__item-title{
    margin-bottom: 20px;
  }

  &__list{

  }

  &__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__info-wrap{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__text-wrap{
    width: min-content;
  }

  &__job-wrap{
    display: flex;
    align-items: center;
  }

  &__name{
    font-family: 'oswald';
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__image-container{
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__picture{
    width: fit-content;
  }

  &__image{
    object-fit: contain;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  }

  &__job-name{
    color: #000000;
    font-weight: 400;
    margin-top: 20px;
  }
}

@include respond-up('s-large') {
  .team {
    padding: 40px;

    &__picture{
      height: 22.33333vw;
    }
  }
}

@include respond-up('medium') {
  .team {
    &__list{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
      grid-row-gap: 60px;
      padding-top: 30px;
    }

    &__item{
      padding: 30px;
      border: 1px solid lightgrey;
      transition: box-shadow .3s;
      &:hover{
        -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      }
      &:first-child{
        grid-column: 1 / 3;
        justify-content: center;
      }
    }

    &__rose-wrap{
      width: 62px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background-color: #FFFFFF;
      box-shadow: 0 0 14px 0 rgba(0,0,0,0.2);
      padding: 5px;
    }

    &__name{
      font-size: calc(20/768 * 100vw);
      font-weight: bold;
    }

    &__job-name{
      //margin-left: 40px;
      font-size: 25px;
    }
  }
}

@include respond('medium') {
  .team {
    padding: 30px;
    &__item-description{
      font-size: 25px;
    }

    &__list{
      grid-gap: 15px;
      padding-top: 30px;
    }

    &__picture{
      height: calc(400/1200 * 100vw);
    }

    &__rose-wrap{
      width: 42px;
      height: 42px;
    }

    &__item{
      padding: 15px;
    }
  }
}

@include respond-down('medium') {
  .team {
    &__job-name{
      font-size: calc(15/768 * 100vw);
      //margin-left: 15px;
    }
  }
}

@include respond-down('small') {
  .team {
    padding: 20px;
    &__item-description{
      font-size: 20px;
    }

    &__list{
      grid-gap: 15px;
      padding-top: 30px;
    }

    &__picture{
      height: 100vw;
    }

    &__rose-wrap{
      width: 42px;
      height: 42px;
    }

    &__name{
      font-size: calc(40/768 * 100vw);
      font-weight: bold;
    }

    &__item{
      padding: 15px;
      border: 1px solid lightgrey;
      margin-bottom: 20px;
    }

    &__job-name{
      font-size: 15px;
    }
  }
}