.index-page {
  &__title, &__subtitle{
    display: block;
  }

  &__title, &__subtitle{
    margin-bottom: 20px;
  }

  &__image{
    margin: 20px 0;
  }
}

@include respond-up('s-large') {
  .index-page {
    padding: 40px;
  }
}

@include respond-up('medium') {
  .index-page {

  }
}

@include respond('medium') {
  .index-page {
    padding: 30px;
  }
}

@include respond-down('medium') {
  .index-page {

  }
}

@include respond-down('small') {
  .index-page {
    padding: 20px;
  }
}