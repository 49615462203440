.articles {
  &__title, &__subtitle{
    display: block;
    margin-bottom: 20px;
  }

  &__tabs{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  &__date{
    display: flex;
    align-items: center;
  }

  &__date-point{
    margin-right: 5px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #15361b;
  }

  &__item-title, &__item-subtitle{
    color: #15361b;
  }

  &__image-container{
    position: relative;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__pagination{
    display: flex;
    justify-content: center;
  }
}

@include respond-up('s-large') {
  .articles {
    padding: 40px;
    &__item-title{
      font-size: calc(35/1980 * 100vw);
      line-height: 39px;
      margin-bottom: 20px;
    }

    &__item-subtitle{
      font-size: calc(20/1980 * 100vw);
      line-height: 24px;
    }

    &__info-wrap{
      padding: 30px;
    }

    &__date{
      margin-bottom: 30px;
    }

    &__image-container{
      height: 15vw;
    }

  }
}

@include respond-up('medium') {
  .articles {
    &__item{
      display: grid;
      grid-template-columns: 30% 1fr;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: unset;
      }
    }

    &__info-wrap{
      border-top: 1px solid lightgrey;
      border-right: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
    }
  }
}

@include respond('medium') {
  .articles {
    padding: 30px;
    &__info-wrap{
      padding: 15px;
    }

    &__item-title{
      font-size: calc(25/1000 * 100vw);
      margin-bottom: 20px;
    }

    &__item-subtitle{
      font-size: calc(15/1000 * 100vw);
      line-height: 24px;
    }

    &__date{
      margin-bottom: 15px;
    }

    &__image-container{
      height: calc(400/1200 * 100vw);
    }
  }
}

@include respond-down('medium') {
  .articles {

  }
}

@include respond-down('small') {
  .articles {
    padding: 20px;

    &__item{
      margin-bottom: 20px;
      display: block;
      &:last-child{
        margin-bottom: unset;
      }
    }

    &__info-wrap{
      padding: 15px;
    }

    &__item-title{
      font-size: 25px;
      margin-bottom: 20px;
    }

    &__item-subtitle{
      font-size: 15px;
      line-height: 24px;
    }

    &__date{
      margin-bottom: 15px;
    }

    &__image-container{
      padding: calc(250/768 * 100vw)
    }

    &__info-wrap{
      border-left: 1px solid lightgrey;
      border-right: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
    }
  }
}