.audios {
  display: flex;
  align-items: center;
  flex-direction: column;
  &__item{
    margin-bottom: 20px;
  }
  &__wrap{
    display: flex;
    flex-direction: column;
  }
  &__name{
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  &__logo{
    width: 100px;
    height: 40px;
    margin-right: 20px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__theme, &__program-name{
    font-size: 18px;
    font-weight: 700;
  }

  &__theme{
    margin-bottom: 10px;
  }

  &__program-name{
    opacity: 0.6;
    margin-left: 10px;
  }

  &__control{
    width: 100%;
  }

  &__item{
    margin-bottom: 20px;
  }
}

@include respond-up('s-large') {
  .audios {
    padding: 40px;
    &__item{
      width: 70%;
    }
  }
}

@include respond-up('medium') {
  .audios {

  }
}

@include respond('medium') {
  .audios {
    padding: 30px;

  }
}

@include respond-down('medium') {
  .audios {
    &__item{
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .audios {
    padding: 20px;
    flex-direction: column;
    &__logo{
      margin-bottom: 10px;
    }
    &__theme, &__program-name{
      font-size: 14px;
      font-weight: 700;
    }
  }
}