.contacts {
  &__phone{
    @extend h3;
    display: block;
    text-align: center;
  }
  &__name, &__phone{
    text-align: center;
  }

  &__subtitle{
    margin-top: 20px;
  }

  &__address{
    margin: 20px 0;
  }
}

@include respond-up('s-large') {
  .contacts {
    padding: 40px;
    &__name{
      font-size: 40px;
    }
  }
}

@include respond-up('medium') {
  .contacts {

  }
}

@include respond('medium') {
  .contacts {
    padding: 30px;
  }
}

@include respond-down('medium') {
  .contacts {

  }
}

@include respond-down('small') {
  .contacts {
    padding: 20px;
  }
}